import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import BaseCard from 'src/components/Cards/BaseCard'
import LanguageContext from 'src/context/language'
import { Heading } from 'src/components/Base'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import {
  cEmployeeCard,
  cPortraitWrapper,
  cPortrait,
  cHeading,
  cContactEmail,
  cPhone,
} from './styles.module.scss'
import classNames from 'classnames'

const EmployeeCard = ({
  portrait,
  firstName,
  familyName,
  extensionNumber,
  email,
}) => {
  const data = useStaticQuery(graphql`
    query employeeCard {
      wp {
        themeGeneralSettings {
          atspOptions {
            phoneAt
            phoneCh
            phoneDe
          }
        }
      }
    }
  `)

  const languageContext = useContext(LanguageContext)
  let prefix = data.wp.themeGeneralSettings.atspOptions.phoneAt

  if (languageContext.currentLocation === 'de') {
    prefix = data.wp.themeGeneralSettings.atspOptions.phoneDe
  } else if (languageContext.currentLocation === 'ch') {
    prefix = data.wp.themeGeneralSettings.atspOptions.phoneCh
  }

  const extensionCH =
    extensionNumber.match(/.{1,2}/g)[0] +
    ' ' +
    extensionNumber.match(/.{1,2}/g)[1]

  const linkClasses = classNames({
    [cContactEmail]: true,
    link: true,
  })
  const fullName = firstName + ' ' + familyName
  const body = (
    <>
      {portrait && (
        <div className={cPortraitWrapper}>
          <GatsbyImage
            className={cPortrait}
            image={getImage(portrait.localFile)}
            alt={portrait.altText}
          />
        </div>
      )}
      <Heading className={cHeading} as="h3" size={3} color="dark">
        {fullName}
      </Heading>
      <a className={cPhone} href={`tel:${prefix + extensionNumber}`}>
        {languageContext.currentLocation !== 'ch' &&
          prefix + '-' + extensionNumber}
        {languageContext.currentLocation === 'ch' && prefix + ' ' + extensionCH}
      </a>{' '}
      <br />
      <a className={linkClasses} href={`mailto:${email}`}>
        {email}
      </a>
    </>
  )

  return <BaseCard className={cEmployeeCard} body={body} hasBorder />
}

EmployeeCard.propTypes = {
  portrait: PropTypes.object,
  firstName: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  extensionNumber: PropTypes.string,
  email: PropTypes.string,
}

EmployeeCard.defaultProps = {
  portrait: undefined,
  email: '',
  extensionNumber: '',
}

export default EmployeeCard
