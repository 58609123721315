import React from 'react'
import { graphql } from 'gatsby'
import { Hero } from 'src/sections'
import { Section, Heading, Wysiwyg, Button } from 'src/components/Base'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import EmployeeCard from 'src/components/Cards/EmployeeCard'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { cEmployeeWrapper } from './styles.module.scss'
import { v4 as uuid } from 'uuid'
import Seo from 'src/components/Seo'

const AboutUsTeam = ({ data }) => {
  const breakpoints = useBreakpoint()

  const page = data.wpPage
  const hero = page.atspHero
  const {
    aboutUsCareer,
    aboutUsTeamAbout,
    aboutUsTeamIntro,
    aboutUsTeamEmployees,
  } = page.atspAboutUsTeam

  let employeesSorted = []
  let employeeList = data.allWpKontakt.nodes

  for (let i = 0; i < employeeList.length; i++) {
    let titleParts = employeeList[i].title.split(' ')
    let firstName = titleParts[0] // Get the first name
    let familyName = titleParts.slice(1).join(' ') // Join everything after the first part for the family name
    let portrait = employeeList[i].atspcontacts.portrait
    let extensionNumber = employeeList[i].atspcontacts.contactData.extension
    let email = employeeList[i].atspcontacts.contactData.email

    employeesSorted.push({
      firstName,
      familyName,
      extensionNumber,
      email,
      portrait,
    })
  }

  employeesSorted = employeesSorted.sort((a, b) => {
    let nameA = a.familyName
    let nameB = b.familyName
    return (nameA > nameB && 1) || -1
  })

  const employees = employeesSorted.map(
    ({ firstName, familyName, extensionNumber, email, portrait }) => (
      <EmployeeCard
        key={uuid()}
        firstName={firstName}
        familyName={familyName}
        extensionNumber={extensionNumber}
        email={email}
        portrait={portrait}
      />
    )
  )

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading as="h2" size={2} color="dark" isCentered>
          {aboutUsTeamIntro.heading}
        </Heading>
        <Wysiwyg isCentered>{aboutUsTeamIntro.wysiwyg}</Wysiwyg>
      </Section>
      <SectionTwoColumns
        rightColumnRemoveDefaultMarginOnMobile
        contentLeft={
          <Heading as="h2" size={2} color="dark" isCentered={breakpoints.lg}>
            {aboutUsTeamAbout.heading}
          </Heading>
        }
        contentRight={<Wysiwyg>{aboutUsTeamAbout.wysiwyg}</Wysiwyg>}
        divider
      />
      <Section hasBackground>
        <Heading as="h2" size={2} color="dark" isCentered>
          {aboutUsTeamEmployees.heading}
        </Heading>
        <div className={cEmployeeWrapper}>{employees}</div>
      </Section>
      <SectionTwoColumns
        rightColumnRemoveDefaultMarginOnMobile
        contentLeft={
          <Heading as="h2" size={2} color="dark" isCentered={breakpoints.lg}>
            {aboutUsCareer.heading}
          </Heading>
        }
        contentRight={
          <>
            <Wysiwyg>{aboutUsCareer.wysiwyg}</Wysiwyg>
            {aboutUsCareer.button && (
              <Button as="a" color="cta" href={aboutUsCareer.button.url}>
                {aboutUsCareer.button.title}
              </Button>
            )}
          </>
        }
        divider
      />
    </>
  )
}

export const pageQuery = graphql`
  query AboutUsTeamById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
      atspAboutUsTeam {
        aboutUsCareer {
          button {
            title
            url
          }
          heading
          wysiwyg
        }
        aboutUsTeamAbout {
          heading
          wysiwyg
        }
        aboutUsTeamEmployees {
          heading
        }
        aboutUsTeamIntro {
          heading
          wysiwyg
        }
      }
    }
    allWpKontakt(sort: { order: ASC, fields: title }) {
      nodes {
        title
        atspcontacts {
          portrait {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 120, height: 120)
              }
            }
          }
          contactData {
            email
            extension
          }
        }
      }
    }
  }
`

export default AboutUsTeam
